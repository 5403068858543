import { useToast } from '@chakra-ui/toast';
import { useCallback } from 'react';
import { useQualification } from '../providers/qualifications';
import { useCheckout } from './use-checkout';

export const useAddToBag = () => {
  const { addLineItems } = useCheckout();
  const toast = useToast();
  const { doIfQualified } = useQualification();

  const addToBag = useCallback(
    async ({ id }: { id: string }) =>
      doIfQualified(() =>
        addLineItems([{ quantity: 1, variantId: id }])
          .then(() => {
            toast({
              title: 'Added to bag!',
              description: "We've added this to your bag.",
              status: 'success',
              isClosable: true,
            });
          })
          .catch(() => {
            toast({
              title: 'Something went wrong!',
              description: "We weren't able to add this to your bag.",
              status: 'warning',
              isClosable: true,
            });
          })
      ),
    [addLineItems, toast, doIfQualified]
  );

  return addToBag;
};
