import { useDisclosure } from '@chakra-ui/react';
import { ProductCard } from '@outdoorly/product-card';
import { useAddToBag } from '../hooks/use-add-to-bag';
import { useAuth } from '../providers/auth';
import { ProductBadge } from './product-badge';
import * as ga from '../lib/ga';
import { getAnalyticsFromProduct } from '../lib/ga/utils';
import dynamic from 'next/dynamic';

const ProductPreviewDynamic = dynamic(() =>
  import('../components/product-preview')
);

export const ProductCardWithPreview = ({ product, ...props }) => {
  const { isAuthed } = useAuth();
  const addToBag = useAddToBag();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const onOpenPreview = () => {
    ga.event({
      action: 'view_item',
      type: 'quick-view',
      ...getAnalyticsFromProduct(product),
    });
    onOpen();
  };

  return (
    <>
      <ProductCard
        {...props}
        product={product}
        onAddToBag={isAuthed ? addToBag : undefined}
        badges={product.tags.map((tag) => (
          <ProductBadge key={tag.tagName} label={tag.tagName} />
        ))}
        onOpenPreview={onOpenPreview}
      />
      {isOpen && (
        <ProductPreviewDynamic
          isOpen={isOpen}
          onClose={onClose}
          productSlug={product.slug}
        />
      )}
    </>
  );
};

ProductCardWithPreview.propTypes = {
  product: ProductCard.propTypes.product,
};
