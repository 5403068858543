import PropTypes from 'prop-types';
import { Badge } from '@outdoorly/badge';
import React from 'react';

function hashCode(s) {
  for (var i = 0, h = 0; i < s.length; i++)
    h = (Math.imul(31, h) + s.charCodeAt(i)) | 0;
  return h;
}

const COLORS = [
  'blue',
  'teal',
  'sage',
  'emerald',
  'gold',
  'tangerine',
  'salmon',
  'violet',
];

function choiceFromColors(s) {
  const hash = Math.abs(hashCode(s));
  const color = COLORS[hash % COLORS.length];
  return color;
}

export const ProductBadge = ({ label, ...props }) => {
  return (
    <Badge
      variant="subtle"
      colorScheme={choiceFromColors(label)}
      label={label}
      maxW="full"
      {...props}
    />
  );
};

ProductBadge.propTypes = {
  label: PropTypes.string.isRequired,
};
