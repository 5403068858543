import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Container } from '@outdoorly/container';

import { Button } from '@outdoorly/button';

import { Grid, Stack } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroller';

import { initializeApollo } from '../../lib/auth/apollo';

import { PageLayout } from '../../components/page-layout';
import { makeNetworkStatus } from '../../hooks/make-network-status';
import { ProductCardWithPreview } from '../../components/product-card-with-preview';

export const LIST_PRODUCT_FRAGMENT = gql`
  fragment productFragment on Product {
    id
    brand {
      id
      name
    }
    slug
    name
    inStock
    tags {
      tagName
    }
    description
    pricing {
      retailPrice {
        currency
        net {
          amount
        }
      }
      userDiscountedPrice {
        currency
        net {
          amount
        }
      }
      discount
    }
    thumbnail {
      url
    }
    secondaryThumbnail {
      url
    }
  }
`;

// variants {
//   id
//   name
//   quantity
//   attributes {
//     attribute {
//       id
//       name
//     }
//     values {
//       id
//       name
//     }
//   }
// }

const GET_PRODUCTS = gql`
  query ProductList($first: Int!, $after: String) {
    products(first: $first, after: $after, onlyVisibleOnStorefront: true) {
      pageInfo {
        hasPreviousPage
        hasNextPage
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          ...productFragment
        }
      }
    }
  }
  ${LIST_PRODUCT_FRAGMENT}
`;

export async function getStaticProps() {
  const apolloClient = initializeApollo();

  await apolloClient.query({
    query: GET_PRODUCTS,
    variables: { first: 20, after: null },
  });

  return {
    props: {
      initialApolloState: apolloClient.extract(),
    },
    revalidate: 1,
  };
}

function Products() {
  const { data, fetchMore, networkStatus } = useQuery(GET_PRODUCTS, {
    variables: { first: 20, after: null },
    notifyOnNetworkStatusChange: true,
  });

  const { fetchingMore: loadingMoreProducts } = makeNetworkStatus(networkStatus);

  return (
    <PageLayout>
      <Container py={6}>
        <Stack spacing={8}>
          <InfiniteScroll
            threshold={500}
            loadMore={() =>
              fetchMore({
                variables: {
                  first: 20,
                  after: data?.products.pageInfo.endCursor,
                },
              })
            }
            hasMore={data?.products.pageInfo.hasNextPage}
          >
            <Grid
              templateColumns={[
                'repeat(2, minmax(0, 1fr))',
                'repeat(2, minmax(0, 1fr))',
                'repeat(4, minmax(0, 1fr))',
              ]}
              gap={4}
            >
              {data?.products?.edges.map(({ node }) => (
                <ProductCardWithPreview key={node.id} product={node} />
              ))}
            </Grid>
          </InfiniteScroll>
          {data?.products.pageInfo.hasNextPage && (
            <Button
              alignSelf="center"
              onClick={() =>
                fetchMore({
                  variables: {
                    first: 20,
                    after: data?.products.pageInfo.endCursor,
                  },
                })
              }
              variant="solid"
              colorScheme="red"
              isLoading={loadingMoreProducts}
              w="xs"
            >
              Load more
            </Button>
          )}
        </Stack>
      </Container>
    </PageLayout>
  );
}

export default Products;
